import { EventEmitter, Injectable } from '@angular/core';
import { UserService } from './user.service';
import { RouteInfo } from '../sidebar/sidebar.component';
import { ClientsService } from './clients.service';
import * as _ from 'lodash';
import { AvailableForm } from 'app/models/AvailableForm';
import { EmployeeServices } from './hris/employee.service';
import { RefreshTokenSubjectService } from './refresh-token-subject.service';

@Injectable({
	providedIn: 'root',
})
export class NavService {
	routes = new EventEmitter<RouteInfo[]>();
	ROUTES: RouteInfo[] = [];
	private availableSections = [
		{
			path: '/forms',
			title: 'Budgeting',
			type: 'sub',
			collapse: 'budgeting',
			children: [],
		},
		{
			path: '/licenses',
			title: 'Licensing',
			type: 'sub',
			collapse: 'permits-licensing',
			children: [],
		},
		{
			path: '/permits',
			title: 'Permitting',
			type: 'sub',
			collapse: 'permits',
			children: [],
		},
		{
			path: '/water-utility',
			title: 'Water Utility',
			type: 'sub',
			collapse: 'water-utility',
			children: [],
		},
		{
			path: '/forms',
			title: 'Purchasing',
			type: 'sub',
			collapse: 'purchasing',
			children: [],
		},
		{
			path: '/forms',
			title: 'Payments',
			type: 'sub',
			collapse: 'payments',
			children: [],
		},
		{
			path: '/miscellaneous-billing',
			title: 'Billing & AR',
			type: 'sub',
			collapse: 'miscellaneous-billing',
			children: [],
		},
		{
			path: '/inventory',
			title: 'Inventory',
			type: 'sub',
			collapse: 'inventory',
			children: [],
		},
		{
			path: '/project-grants',
			title: 'Projects & Grants',
			type: 'sub',
			collapse: 'project-grants',
			children: [],
		},
		{
			path: '/capital-assets',
			title: 'Capital Assets',
			type: 'sub',
			collapse: 'capital-item',
			children: [],
		},
		{
			path: '/forms',
			title: 'Task Management',
			type: 'sub',
			collapse: 'task-mng',
			children: [],
		},
		{
			path: '/forms',
			title: 'Cashiering',
			type: 'sub',
			collapse: 'cashiering-report',
			children: [],
		},
		{
			path: '/forms',
			title: 'HR and Payroll',
			type: 'sub',
			collapse: 'hr',
			children: [],
		},
		{
			path: '/forms',
			title: 'HR',
			type: 'sub',
			collapse: 'hris',
			children: [],
		},
		{
			path: '/forms',
			title: 'Payroll',
			type: 'sub',
			collapse: 'payroll',
			children: [],
		},
		{
			path: '/forms',
			title: 'Help Desk',
			type: 'sub',
			collapse: 'help-desk',
			children: [],
		},
		{
			path: '/generalledger',
			title: 'General Ledger',
			type: 'sub',
			collapse: 'general-ledger',
			children: [],
		},
		{
			path: '/generalledger',
			title: 'Treasury Management',
			type: 'sub',
			collapse: 'treasury-management',
			children: [],
		},
		{
			path: '/generalledger',
			title: 'Reporting',
			type: 'sub',
			collapse: 'custom-reporting',
			children: [],
		},
		{
			path: '/capital-lease',
			title: 'Lease Accounting',
			type: 'sub',
			collapse: 'capital-lease-accounting',
			children: [],
		},
		{
			path: '/utility-billing',
			title: 'Utility Billing',
			type: 'sub',
			collapse: 'utility-billing',
			children: [],
		},
		{
			path: '/forms',
			title: 'Implementation',
			type: 'sub',
			collapse: 'implementation',
			children: [],
		},
		{
			path: '/templates',
			title: 'Templates',
			type: 'sub',
			collapse: 'templates',
			children: [],
		},
		{
			path: '/workflow-management',
			title: 'Workflow Management',
			type: 'sub',
			collapse: 'workflow-management',
			children: [],
		},
		{
			path: '/user-management',
			title: 'User Management',
			type: 'sub',
			collapse: 'user-management',
			children: [],
		},
		{
			path: '/training',
			title: 'Training',
			type: 'sub',
			collapse: 'training',
			children: [],
		},
		{
			path: '/code-enforcement',
			title: 'Code Enforcement',
			type: 'sub',
			collapse: 'code-enforcement',
			children: [],
		},
		{
			path: '/announcements',
			title: 'Announcements',
			type: 'sub',
			collapse: 'announcements',
			children: [],
		},
		{
			path: '/dynamic-forms',
			title: 'Dynamic Forms',
			type: 'sub',
			collapse: 'dynamic-forms',
			children: [],
		},
		{
			path: '/integrations',
			title: 'Integrations',
			type: 'sub',
			collapse: 'integrations',
			children: [],
		},
		{
			path: '/utility-billing',
			title: 'Utility Billing',
			type: 'sub',
			collapse: 'utility-billing',
			children: [],
		},
		{
			path: '/land-management',
			title: 'Land Management',
			type: 'sub',
			collapse: 'land-management',
			children: [],
		},
		{
			path: '/recreation',
			title: 'Recreation',
			type: 'sub',
			collapse: 'recreation',
			children: [],
		},
		{
			path: '/segmentation',
			title: 'Segmentation',
			type: 'sub',
			collapse: 'segmentation',
			children: [],
		},
	];

	constructor(
		private userServices: UserService,
		private clientServices: ClientsService,
		private employeeServices: EmployeeServices,
		private refreshTokenSubjectService: RefreshTokenSubjectService
	) {
		this.updateNav();

		this.refreshTokenSubjectService.loginSuccess.subscribe((data) => {
			if (data) {
				location.reload();
			}
		});
	}

	private initNav() {
		for (let item of this.availableSections) {
			item.children = [];
		}
	}

	updateNav() {
		this.ROUTES = [];
		let user = this.userServices.currentUserValue?.user;
		let client = user?.clientId;
		let isAdmin = this.userServices.isAdmin();

		const adminNav: RouteInfo[] = [
			{
				path: '/admin',
				title: 'Administrator',
				type: 'sub',
				collapse: 'Administrator',
				children: [
					{ path: 'clients/list', title: 'Client List', ab: 'CL', enabled: true },
					{ path: 'clients/add', title: 'Add Client', ab: 'AD', enabled: true },
					{ path: 'users/list', title: 'User List', ab: 'UL', enabled: true },
					{ path: 'users/add', title: 'Add User', ab: 'AU', enabled: true },
					{ path: 'configuration/list', title: 'Configurations List', ab: 'CONF', enabled: true },
					{ path: 'roles', title: 'Roles', ab: 'PR', enabled: true },
				],
				order: 0,
				isLocalSection: true,
			},
		];
		const clientAdminNav: RouteInfo[] = [
			{
				path: '/',
				title: 'Users',
				type: 'sub',
				collapse: 'ClientAdmin',
				children: [],
				enabled: true,
				order: 0,
				isLocalSection: true,
			},
		];

		const entireNav: RouteInfo[] = [
			{
				path: '/dashboard',
				title: 'Dashboard',
				type: 'link',
				order: 1,
				enabled: true,
			},
			{
				path: '/pages/form-search',
				title: 'Form Search',
				type: 'link',
				order: 2,
				enabled: true,
			},
			{
				path: '/pages/quick-approval',
				title: 'Quick Approval',
				type: 'link',
				order: 3,
				enabled: true,
			},
		];

		this.initNav();

		if (user && user.claims && user?.claims[user.clientId]?.canAddUser) {
			clientAdminNav[0].children.push({ path: 'client-users/add', title: 'Add User', ab: 'CAU', enabled: true });
		}

		if (user && user.claims && user.claims[user.clientId]?.canViewUser) {
			clientAdminNav[0].children.push({ path: 'client-users/list', title: 'Users List', ab: 'CUL', enabled: true });
		}

		if (isAdmin) {
			this.ROUTES = adminNav;
		} else if (
			(user && user.claims && user.claims[user.clientId]?.canViewUser) ||
			(user && user.claims && user.claims[user.clientId]?.canAddUser)
		) {
			this.ROUTES = clientAdminNav;
		}

		if (isAdmin) {
			this.ROUTES.push({
				path: '/pages/issues-report',
				type: 'link',
				order: 0.1,
				title: 'Issues Report',
				enabled: true,
			});
		}

		if (this.userServices.isAclarianEmployee() || isAdmin) {
			this.ROUTES.push({
				path: '/forms/monthly-timesheet',
				title: 'Monthly Time Sheet',
				type: 'link',
				order: 1.1,
				enabled: true,
			});
		}

		if (this.userServices?.currentUserValue?.user?.clients?.length > 1) {
			this.ROUTES.push({
				path: '/multi-client-dashboard',
				title: 'Multi Client Dashboard',
				type: 'link',
				order: 0.2,
				enabled: true,
			});
		}

		if (client || this.userServices.isAclarianEmployee()) {
			for (let nav of entireNav) {
				this.ROUTES.push(nav);
			}
		}

		if (user) {
			if (client) {
				this.clientServices.getClientForms(client).subscribe((data) => {
					this.employeeServices
						.getLogedInEmployeeId(this.userServices.currentUserValue.user.clientId)
						.subscribe((employeeId) => {
							if (!employeeId) {
								data = data.filter((route) => !route.path?.includes('employee/details'));
							}
							this.setupFormTwo(data.filter((t) => t.isEnabled));
						});
				});
			} else {
				this.routes.emit(this.ROUTES);
			}
		}
	}

	private setupFormTwo(availableItems: AvailableForm[]) {
		let isAdmin = this.userServices.isAdmin();
		let user = this.userServices.currentUserValue?.user;
		let client = user?.clientId;
		let clientForms = availableItems.filter((t) => t.isEnabled);

		for (let clientForm of clientForms) {
			clientForm.isEnabled =
				user.claims[client]?.claims.find((z) => z.id == clientForm.id)?.visible || false || isAdmin;
		}

		if (user.id == '9219a0e3-c237-49cb-99bf-a70a8bb29ddb') {
			clientForms.push({
				clientIds: [],
				id: 'monthly-timesheet',
				isEnabled: true,
				name: 'Monthly Time Sheet',
				path: '/forms/monthly-timesheet',
				section: 'HR and Payroll',
				sectionId: 'hr',
				showInDashboard: true,
				type: 'link',
			});
		}

		this.userServices.availableForms = clientForms.filter((t) => t.isEnabled);
		this.userServices.availableFormsEvent.emit(this.userServices.availableForms);

		const groupedSections = _(this.userServices.availableForms)
			.groupBy((t) => t.sectionId)
			.map((value, key) => {
				return { name: key, items: value };
			})
			.value();

		for (let group of groupedSections) {
			const forms = _(group.items)
				.orderBy((t) => t.name)
				.value();
			let section = this.availableSections.find((t) => t.collapse == group.name);

			if (section != null) {
				for (let form of forms) {
					let item = {
						type: form.type,
						path: form.path,
						title: form.name,
						id: form.id,
					};
					section.children.push(item);
				}

				for (let child of section.children) {
					child.enabled = user.claims[client]?.claims.find((z) => z.id == child.id)?.visible || false;
				}

				// @ts-ignore
				section.enabled = section.children?.some((y) => y.enabled) || false;

				if (section.children.length > 0) {
					this.ROUTES.push(section);
				}
			}
		}

		this.ROUTES = _(this.ROUTES)
			.orderBy((t) => t.title)
			.value();
		this.ROUTES = _(this.ROUTES)
			.orderBy((t) => t.order)
			.value();

		for (let item of this.ROUTES) {
			item.children = _(item.children)
				.orderBy((t) => t.title)
				.value();
		}

		this.routes.emit(this.ROUTES);
	}
}
