import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { ApInvoiceDuplicateSearchRequest } from 'app/models/form/ApInvoiceDuplicateSearchRequest';
import { QuickPayView } from 'app/models/form/QuickPayFormView';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UtilityService } from '../utility.service';

@Injectable({
	providedIn: 'root',
})
export class QuickPayService {
	private readonly urlPath = 'quick-pay';
	private duplicateRequest = null;
	private response: Observable<QuickPayView[]>;
	private showDuplicateInvoicePopup = new BehaviorSubject<boolean>(false);

	constructor(private httpClient: HttpClient, private utilityService: UtilityService) { }
	createForm(clientId: string, request: any): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}`, this.setupRequest(request))
			.pipe();
	}

	setupRequest(request: any): FormData {
		const formData: FormData = new FormData();

		for (let i = 0; i < request.attachments.length; i++) {
			const name = `attachment-${i + 1}`;
			formData.append(name, request.attachments[i].file);
			request.attachments[i].name = name;
		}
		formData.append('model', JSON.stringify(request));
		return formData;
	}
	deleteDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(
			`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/docs/${docId}`
		);
	}
	updateForm(clientId: string, formId: string, request: any): Observable<any> {
		return this.httpClient
			.put<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}`, this.setupRequest(request))
			.pipe();
	}
	updateCashPostingDate(clientId: string, id: any, cashPostingDate: Date): Observable<any> {
		const body = { cashPostingDate: cashPostingDate };
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/updateCashPostingDate/${id}`, body)
			.pipe();
	}

	getForm(clientId: string, formId: string): Observable<QuickPayView> {
		return this.httpClient
			.get<QuickPayView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}`)
			.pipe();
	}

	approveForm(clientId: string, formId: string, userId: any): Observable<QuickPayView> {
		return this.httpClient
			.post<QuickPayView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/approve`, {
				userId,
			})
			.pipe();
	}

	rejectForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: string; workflowId: number }
	): Observable<QuickPayView> {
		return this.httpClient
			.post<QuickPayView>(
				`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/decline`,
				rejectionReason
			)
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${environment.apiUrl}forms/clients/${clientId}/${this.urlPath
			}/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	sendBack(clientId: string, formId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/review`, {})
			.pipe();
	}

	getQuickPayBy(clientId: string, vendor: string, glAccount: string, amount: number): Observable<QuickPayView[]> {
		return this.httpClient
			.get<QuickPayView[]>(
				`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/invoices/${vendor}/${glAccount}?amount=${amount}`
			)
			.pipe();
	}

	deleteForm(clientId: string, id: string) {
		return this.httpClient.delete(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}`).pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<QuickPayView> {
		return this.httpClient
			.post<QuickPayView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
	convertToApinvoice(clientId: string, id: any, poNumber: string, userId): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}/convert-form/${poNumber}`, {
				userId,
			})
			.pipe();
	}
	convertToCheckRequest(clientId: string, id: any, userId): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}/convert-check-request-form`, {
				userId,
			})
			.pipe();
	}
	getRecurringForms(clientId: string): Observable<any[]> {
		return this.httpClient
			.get<any[]>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/recurring-forms`)
			.pipe();
	}

	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest('POST', `${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}/docs`, formData, {
			reportProgress: true,
			responseType: 'json',
		});
		return this.httpClient.request(req);
	}

	showDuplicateInvoicePopupChange$ = this.showDuplicateInvoicePopup.asObservable();
	setShowDuplicateInvoicePopup(value: boolean) {
		this.showDuplicateInvoicePopup.next(value);
	}

	convertInvoiceIntoQuickPay(clientId: string, invoice: any) {
		const formData: FormData = new FormData();
		formData.append('SampleInvoice', invoice);
		return this.httpClient.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/convert-invoice-to-quickpay`, formData).pipe();
	}
	allowBudgetToleranceOverride(clientId: string, formId: string, approverId: string): Observable<any> {
		return this.httpClient.patch(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/approver/${approverId}`, {}).pipe();
	}
}
