import { Observable } from 'rxjs';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { UtilityService } from 'app/services/utility.service';
import { BaseApiService } from 'app/services/BaseApiService';
import { FormCommentView } from 'app/models/form/FormCommentView';
import {environment} from 'environments/environment';

export class BaseFormService extends BaseApiService {
	constructor(urlPath: string, httpClient: HttpClient, utilityService: UtilityService) {
		super(urlPath, httpClient, utilityService);
	}

	rejectForm(clientId: string, id: string, rejectionReason: { reason: string; workflowId: number }): Observable<any> {
		return this.httpClient.post<any>(`${this.getUrl(clientId)}/${id}/decline`, rejectionReason).pipe();
	}

	approveForm(clientId: string, id: string, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approve`, {
				userId,
			})
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${this.getUrl(clientId)}/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	sendBack(clientId: string, id: string): Observable<any> {
		return this.httpClient.post<any>(`${this.getUrl(clientId)}/${id}/review`, {}).pipe();
	}

	sendComment(clientId: string, formId: string, item: FormCommentView, file: File): Observable<FormCommentView> {
		const formData: FormData = new FormData();
		if (file !== null && file !== undefined) {
			formData.append(file.name, file);
		}
		formData.append('model', JSON.stringify(item));
		return this.httpClient.post<FormCommentView>(`${this.getUrl(clientId)}/${formId}/comments`, formData).pipe();
	}

	uploadDocument(clientId: string, formId, formData: FormData): Observable<HttpEvent<any>> {
		const options = {
			headers: new HttpHeaders({
				'x-fileHash': formData.get('fileHash').toString(),
			}),
		};
		const req = new HttpRequest('POST', `${this.getUrl(clientId)}/${formId}/documents`, formData, {
			reportProgress: true,
			responseType: 'json',
			...options,
		});
		return this.httpClient.request(req);
	}

	deleteForm(clientId: string, id: string) {
		return this.httpClient.delete(`${this.getUrl(clientId)}/${id}`).pipe();
	}

	deleteDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(`${this.getUrl(clientId)}/${formId}/documents/${docId}`);
	}

	createFormWithDocuments(clientId: string, request: any): Observable<any> {
		return this.httpClient.post<any>(`${this.getUrl(clientId)}`, this.setupRequest(request)).pipe();
	}

	updateFormWithDocuments(clientId: string, formId: any, request: any): Observable<any> {
		return this.httpClient.put<any>(`${this.getUrl(clientId)}/${formId}`, this.setupRequest(request)).pipe();
	}

	setupRequest(request: any): FormData {
		const formData: FormData = new FormData();

		for (let i = 0; i < request.attachments.length; i++) {
			const name = `attachment-${i + 1}`;
			formData.append(name, request.attachments[i].file);
			request.attachments[i].name = name;
		}
		formData.append('model', JSON.stringify(request));
		return formData;
	}

	patchForm(clientId: string, formId: string, pathObject: {}): Observable<any> {
		let requestObject = [];
		for (let key in pathObject) {
			requestObject.push({
				op: 'replace',
				path: `/${key}`,
				value: pathObject[key],
			});
		}

		return this.httpClient.patch(`${this.getUrl(clientId)}/${formId}`, requestObject).pipe();
	}
	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest('POST', `${this.getUrl(clientId)}/${id}/documents`, formData, {
			reportProgress: true,
			responseType: 'json',
		});
		return this.httpClient.request(req);
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
	allowBudgetToleranceOverride(clientId: string, formId: string, approverId: string): Observable<any> {
		return this.httpClient.patch(`${this.getUrl(clientId)}/${formId}/approver/${approverId}`, {}).pipe();
	}
}
