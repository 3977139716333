import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilityService } from 'app/services/utility.service';
import { environment } from 'environments/environment';
import { BaseFormService } from 'app/services/BaseFormService';
import { AccountFilter } from 'app/models/general-ledger/AccountFilter';
import { AccountView } from 'app/models/general-ledger/AccountView';
import {AccountMappingView} from 'app/models/general-ledger/AccountMappingView';

@Injectable({
	providedIn: 'root',
})
export class AccountService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}general-ledger/clients/$$clientId$$/forms/accounts`, httpClient, utilityService);
	}

	validateAccounts(clientId: string, request: any): Observable<string[]> {
		return this.httpClient.post<string[]>(`${this.getUrl(clientId)}/validate-account`, request).pipe();
	}

	getNextAccountNumber(clientId: string, subObjectCode: string): Observable<string> {
		let url = `${this.getUrl(clientId)}/nextaccount/${subObjectCode}`;
		return this.httpClient.get<string>(url).pipe();
	}

	updateAccount(clientId: string, id: string, request: any): Observable<any> {
		return this.httpClient.put<any>(`${this.getUrl(clientId)}/update-account/${id}`, request).pipe();
	}
	updateGLAccount(clientId: string, id: string, request: any): Observable<any> {
		return this.httpClient.put<any>(`${this.getUrl(clientId)}/update-gl-account/${id}`, request).pipe();
	}

	searchAccounts(clientId: string, filter: AccountFilter): Observable<AccountView[]> {
		return this.httpClient.post<AccountView[]>(`${this.getUrl(clientId)}/search`, filter).pipe();
	}
	updateForm(clientId: string, id: string, request: any): Observable<any> {
		return this.httpClient.put<any>(`${this.getUrl(clientId)}/${id}`, request).pipe();
	}
	importAccounts(clientId: string, importedAccounts: AccountView[]): Observable<AccountView[]> {
		return this.httpClient.post<AccountView[]>(`${this.getUrl(clientId)}/import-accounts`, importedAccounts).pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	getAccountMappings(clientId: string): Observable<AccountMappingView[]> {
		return this.httpClient.get<AccountMappingView[]>(`${this.getUrl(clientId)}/mappings`).pipe();
	}

	getAccountMappingFor(clientId: string, accountCode: string): Observable<AccountMappingView> {
		return this.httpClient.get<AccountMappingView>(`${this.getUrl(clientId)}/mappings/${accountCode}`).pipe();
	}

	updateAccountMapping(clientId: string, legacyAccountCode: string, request: AccountMappingView): Observable<AccountMappingView> {
		return this.httpClient.post<AccountMappingView>(`${this.getUrl(clientId)}/mappings/${legacyAccountCode}`, request).pipe();
	}

	deleteAccountMapping(clientId: string, legacyAccountCode: string): Observable<any> {
		return this.httpClient.delete(`${this.getUrl(clientId)}/mappings/${legacyAccountCode}`).pipe();
	}
}
