import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { CustomerFormView } from 'app/models/miscellaneous-billing/Customer-form';
import { CustomerInvoiceFormView } from 'app/models/miscellaneous-billing/CustomerInvoiceFormView';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';
import { DynamicFormUtilityServices } from '../dynamic-form-utility.service';

@Injectable({
	providedIn: 'root',
})
export class IndividualPayeeFormService {
	private readonly urlPath = `${environment.apiUrl}forms/clients/`;

	constructor(
		private httpClient: HttpClient,
		private utilityService: UtilityService,
		private dynamicFormUtilityServices: DynamicFormUtilityServices
	) {}

	createForm(clientId: string, request: any, prefix: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/individual-payee-form`, this.setupRequest(request, prefix))
			.pipe();
	}

	updateForm(clientId: string, id: string, request: any, prefix: string): Observable<any> {
		return this.httpClient
			.put<any>(`${this.urlPath}${clientId}/individual-payee-form/${id}`, this.setupRequest(request, prefix))
			.pipe();
	}

	private setupRequest(request: any, prefix: string) {
		const formData: FormData = new FormData();

		formData.append('model', JSON.stringify(request));

		//Append Addtional fields Files
		this.dynamicFormUtilityServices.appendDocuments(formData, prefix, request);

		return formData;
	}

	getForm(clientId: string, id: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/individual-payee-form/${id}`).pipe();
	}
	deleteDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(`${this.urlPath}${clientId}/individual-payee-form/${formId}/docs/${docId}`);
	}

	getPayees(clientId: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/individual-payee-form/payees`).pipe();
	}

	filterPayees(clientId: string, searchTerm: string = ''): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/individual-payee-form/filter-payees`, {
			params: { searchTerm }
		});
	}
	
	getInitialPayees(clientId: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/individual-payee-form/initial-payees`);
	}

	rejectForm(clientId: string, id: string, rejectionReason: { reason: string; workflowId: number }): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/individual-payee-form/${id}/decline`, rejectionReason)
			.pipe();
	}

	approveForm(clientId: string, id: string, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/individual-payee-form/${id}/approve`, {
				userId,
			})
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${
			this.urlPath
		}${clientId}/individual-payee-form/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	sendBack(clientId: string, id: string): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/individual-payee-form/${id}/review`, {}).pipe();
	}

	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest('POST', `${this.urlPath}${clientId}/individual-payee-form/${id}/docs`, formData, {
			reportProgress: true,
			responseType: 'json',
		});
		return this.httpClient.request(req);
	}

	deleteForm(clientId: string, id: string) {
		return this.httpClient.delete(`${this.urlPath}${clientId}/individual-payee-form/${id}`).pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<CustomerInvoiceFormView> {
		return this.httpClient
			.post<CustomerInvoiceFormView>(`${this.urlPath}${clientId}/individual-payee-form/${id}/approval`, {
				userId,
			})
			.pipe();
	}
	search(clientId: string): Observable<CustomerFormView[]> {
		return this.httpClient.get<CustomerFormView[]>(`${this.urlPath}${clientId}/individual-payee-form/search`).pipe();
	}

	exportPayees(clientId: string) {
		return this.httpClient
			.get(`${this.urlPath}${clientId}/individual-payee-form/export`, {
				responseType: 'blob',
			})
			.pipe();
	}
}
