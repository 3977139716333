import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';
import { BudgetView } from 'app/models/BudgetView';
import { FundView } from 'app/models/FundView';
import { environment } from 'environments/environment';
import { DepartmentBudgetView } from 'app/models/DepartmentBudgetView';
import { BudgetSummary } from 'app/models/BudgetSummary';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { PurchaseOrderReportView } from 'app/models/PurchaseOrderAccountView';
import { PersonnelSummary } from 'app/models/PersonnelSummary';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { BudgetAmendmentView } from 'app/models/form/BudgetAmendmentView';
import { BudgetTransferView } from 'app/models/form/BudgetTransferView';
import { BudgetBalance } from 'app/models/budget/budget-balance';

@Injectable({
	providedIn: 'root',
})
export class BudgetServices {
	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}

	getFunds(clientId: string, accountType?: string): Observable<FundView[]> {
		return this.httpClient
			.get<FundView[]>(`${environment.apiUrl}lookup/clients/${clientId}/funds?accountType=${accountType}`)
			.pipe();
	}

	getBudgetFor(clientId: string, departmentId: number): Observable<DepartmentBudgetView> {
		return this.httpClient
			.get<DepartmentBudgetView>(
				`${environment.apiUrl}budgets/clients/${clientId}/departments/expenditure/${departmentId}`
			)
			.pipe();
	}

	getBudgetForAllDepartments(clientId: string): Observable<DepartmentBudgetView[]> {
		return this.httpClient
			.get<DepartmentBudgetView[]>(`${environment.apiUrl}budgets/clients/${clientId}/departments/expenditure`)
			.pipe();
	}

	getOpenPos(clientId: string, departmentId: number): Observable<PurchaseOrderReportView[]> {
		return this.httpClient
			.get<PurchaseOrderReportView[]>(
				`${environment.apiUrl}budgets/clients/${clientId}/departments/${departmentId}/open-po`
			)
			.pipe();
	}

	getBudgetInformation(clientId: string, gl: any, fiscalYear: any): Observable<BudgetView> {
		return this.httpClient
			.get<BudgetView>(`${environment.apiUrl}budgets/clients/${clientId}/${gl}/?fiscalYear=${fiscalYear}`)
			.pipe();
	}

	createTransferForm(clientId: string, request: BudgetTransferView): Observable<BudgetTransferView> {
		return this.httpClient
			.post<BudgetTransferView>(`${environment.apiUrl}forms/clients/${clientId}/budget-forms/transfer`, request)
			.pipe();
	}

	updateTransferForm(clientId: string, id: string, request: any): Observable<BudgetTransferView> {
		return this.httpClient
			.put<BudgetTransferView>(`${environment.apiUrl}forms/clients/${clientId}/budget-forms/transfer/${id}`, request)
			.pipe();
	}

	uploadTransferFile(clientId: string, formId, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest(
			'POST',
			`${environment.apiUrl}forms/clients/${clientId}/budget-forms/transfer/${formId}/docs`,
			formData,
			{
				reportProgress: true,
				responseType: 'json',
			}
		);
		return this.httpClient.request(req);
	}

	deleteTransferDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(
			`${environment.apiUrl}forms/clients/${clientId}/budget-forms/transfer/${formId}/docs/${docId}`
		);
	}

	getBudgetTransfer(clientId: string, formId: string): Observable<BudgetTransferView> {
		return this.httpClient
			.get<BudgetTransferView>(`${environment.apiUrl}forms/clients/${clientId}/budget-forms/transfer/${formId}`)
			.pipe();
	}

	approveTransferForm(clientId: string, formId: string, userId: any): Observable<BudgetTransferView> {
		return this.httpClient
			.post<BudgetTransferView>(
				`${environment.apiUrl}forms/clients/${clientId}/budget-forms/transfer/${formId}/approve`,
				{
					userId,
				}
			)
			.pipe();
	}

	rejectTransferForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: any; workflowId: any }
	): Observable<BudgetTransferView> {
		return this.httpClient
			.post<BudgetTransferView>(
				`${environment.apiUrl}forms/clients/${clientId}/budget-forms/transfer/${formId}/decline`,
				rejectionReason
			)
			.pipe();
	}

	submitForApprovalTransfer(clientId: string, id: any, userId: string): Observable<BudgetTransferView> {
		return this.httpClient
			.post<BudgetTransferView>(`${environment.apiUrl}forms/clients/${clientId}/budget-forms/transfer/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	sendTransferBack(clientId: string, formId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/budget-forms/transfer/${formId}/review`, {})
			.pipe();
	}

	getDashboardBudgetTransfer(
		clientId: string,
		userId: string,
		search: FormSearch
	): Observable<BaseDashboard<BudgetTransferView>> {
		let url = `${
			environment.apiUrl
		}forms/clients/${clientId}/budget-forms/transfer/users/${userId}?${this.utilityService.objectToQueryString(
			search
		)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	getDashboardBudgetAmendment(
		clientId: string,
		userId: string,
		search: FormSearch
	): Observable<BaseDashboard<BudgetAmendmentView>> {
		let url = `${
			environment.apiUrl
		}forms/clients/${clientId}/budget-forms/amendment/users/${userId}?${this.utilityService.objectToQueryString(
			search
		)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	createAmendmentForm(clientId: string, request: any): Observable<BudgetAmendmentView> {
		return this.httpClient
			.post<BudgetAmendmentView>(`${environment.apiUrl}forms/clients/${clientId}/budget-forms/amendment`, request)
			.pipe();
	}

	updateAmendmentForm(clientId: string, id: string, request: any): Observable<BudgetAmendmentView> {
		return this.httpClient
			.put<BudgetAmendmentView>(`${environment.apiUrl}forms/clients/${clientId}/budget-forms/amendment/${id}`, request)
			.pipe();
	}

	uploadAmendmentFile(clientId: string, formId, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest(
			'POST',
			`${environment.apiUrl}forms/clients/${clientId}/budget-forms/amendment/${formId}/docs`,
			formData,
			{
				reportProgress: true,
				responseType: 'json',
			}
		);
		return this.httpClient.request(req);
	}

	deleteAmendmentDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(
			`${environment.apiUrl}forms/clients/${clientId}/budget-forms/amendment/${formId}/docs/${docId}`
		);
	}

	getBudgetAmendment(clientId: string, formId: string): Observable<BudgetAmendmentView> {
		return this.httpClient
			.get<BudgetAmendmentView>(`${environment.apiUrl}forms/clients/${clientId}/budget-forms/amendment/${formId}`)
			.pipe();
	}

	approveAmendmentForm(clientId: string, formId: string, userId: any): Observable<BudgetAmendmentView> {
		return this.httpClient
			.post<BudgetAmendmentView>(
				`${environment.apiUrl}forms/clients/${clientId}/budget-forms/amendment/${formId}/approve`,
				{
					userId,
				}
			)
			.pipe();
	}

	rejectAmendmentForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: any; workflowId: any }
	): Observable<BudgetAmendmentView> {
		return this.httpClient
			.post<BudgetAmendmentView>(
				`${environment.apiUrl}forms/clients/${clientId}/budget-forms/amendment/${formId}/decline`,
				rejectionReason
			)
			.pipe();
	}

	sendAmendmentBack(clientId: string, formId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/budget-forms/amendment/${formId}/review`, {})
			.pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<BudgetAmendmentView> {
		return this.httpClient
			.post<BudgetAmendmentView>(
				`${environment.apiUrl}forms/clients/${clientId}/budget-forms/amendment/${id}/approval`,
				{
					userId,
				}
			)
			.pipe();
	}

	getFutureBudgetForFund(clientId: string, fundId: string, accountType: string): Observable<BudgetSummary> {
		return this.httpClient
			.get<BudgetSummary>(
				`${environment.apiUrl}budgets/clients/${clientId}/funds/${fundId}/next-year?accountType=${accountType}`
			)
			.pipe();
	}
	getFutureBudgetForFundAndYear(clientId: string, fundId: string, accountType: string, fiscalYear: any): Observable<BudgetSummary> {
		return this.httpClient
			.get<BudgetSummary>(
				`${environment.apiUrl}budgets/clients/${clientId}/funds/${fundId}/by-year?accountType=${accountType}&fiscalYear=${fiscalYear}`
			)
			.pipe();
	}
	getNextYearPersonnelBudgetDetails(clientId: string): Observable<PersonnelSummary[]> {
		return this.httpClient
			.get<PersonnelSummary[]>(`${environment.apiUrl}budgets/clients/${clientId}/next-year/personnel/details`)
			.pipe();
	}

	getFutureBudgetForDepartment(clientId: string, fundId: string, departmentId: string): Observable<BudgetSummary> {
		return this.httpClient
			.get<BudgetSummary>(
				`${environment.apiUrl}budgets/clients/${clientId}/funds/${fundId}/departments/${departmentId}/next-year`
			)
			.pipe();
	}
	getFutureBudgetForDepartmentByYear(clientId: string, fundId: string, departmentId: string, fiscalYear:any): Observable<BudgetSummary> {
		return this.httpClient
			.get<BudgetSummary>(
				`${environment.apiUrl}budgets/clients/${clientId}/funds/${fundId}/departments/${departmentId}/by-year/${fiscalYear}`
			)
			.pipe();
	}

	getPersonnelBudgetSummary(clientId: string, departmentId: any) {
		return this.httpClient
			.get<BudgetSummary>(
				`${environment.apiUrl}budgets/clients/${clientId}/departments/${departmentId}/next-year/personnel`
			)
			.pipe();
	}

	getBudgetReport(clientId: string, fiscalYear: number, addMonthlyBudgetInfo): Observable<BudgetBalance[]> {
		return this.httpClient
			.get<BudgetBalance[]>(
				`${environment.apiUrl}budgets/clients/${clientId}/balance/management?fiscalYear=${fiscalYear}&addMonthlyBudgetInfo=${addMonthlyBudgetInfo}`
			)
			.pipe();
	}

	getBudgetMultiYearReport(clientId: string): Observable<BudgetBalance[]> {
		return this.httpClient
			.get<BudgetBalance[]>(`${environment.apiUrl}budgets/clients/${clientId}/balance/multi-year/management`)
			.pipe();
	}

	updateBudgetReport(clientId: string, budgetReport: BudgetBalance): Observable<BudgetBalance> {
		return this.httpClient
			.post<BudgetBalance>(`${environment.apiUrl}budgets/clients/${clientId}/balance/management`, budgetReport)
			.pipe();
	}

	updateMultipleBudgetReport(clientId: string, budgetBalances: BudgetBalance[]): Observable<any> {
		return this.httpClient
			.post(`${environment.apiUrl}budgets/clients/${clientId}/balance/management/multiple`, budgetBalances)
			.pipe();
	}

	getBudgetForAccount(clientId: string, generalLedgerId: string, fiscalYear: number): Observable<BudgetBalance> {
		return this.httpClient
			.get<BudgetBalance>(
				`${environment.apiUrl}budgets/clients/${clientId}/balance/management/account/${generalLedgerId}?fiscalYear=${fiscalYear}`
			)
			.pipe();
	}
}
