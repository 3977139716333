import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';
import { HttpClient } from '@angular/common/http';
import { BaseFormService } from '../BaseFormService';

@Injectable({
    providedIn: 'root'
})
export class FieldPaymentRequestFormService extends BaseFormService {
    constructor(httpClient: HttpClient, utilityService: UtilityService) {
        super(`${environment.apiUrl}forms/clients/$$clientId$$/field-payment-request-form`, httpClient, utilityService);
    }
    submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
        return this.httpClient
            .post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
                userId,
            })
            .pipe();
    }
    changeStatus(clientId: string, id: string) {
        return this.httpClient
            .get<any>(`${this.getUrl(clientId)}/${id}/change-status`)
            .pipe();
    }
    getAllFieldPaymentRequests(clientId: string, type: any, vendorId: any, paymentFormId: any) {
        return this.httpClient
            .get<any>(`${this.getUrl(clientId)}/${type}/${vendorId}/search?paymentFormId=${paymentFormId}`)
            .pipe();
    }

    getFieldPaymentRequestById(clientId: string, id: any) {
        return this.httpClient
            .get<any>(`${this.getUrl(clientId)}/${id}`)
            .pipe();
    }

    exportToPdf(clientId: string, id: string): any {
        return this.httpClient
            .get(`${this.getUrl(clientId)}/${id}/export`, {
                responseType: 'blob',
            })
            .pipe();
    }
}